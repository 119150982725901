import React from "react";
import Presentation from "./presentation";
import Technologies from "./technologies";

function About(): JSX.Element {
  return (
    <section id="propos" className="pb-20 bg-gray-300 -mt-24">
      <div className="container mx-auto px-4">
        <Technologies />
        <Presentation />
      </div>
    </section>
  );
}

export default About;
