import React, { FunctionComponent } from "react";

const FooterComponent: FunctionComponent = () => {

  const date = new Date().getFullYear();

  return (
    <footer className="pt-8 pb-6">
      <div className="container mx-auto px-4">
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-white font-semibold py-1">
              Copyright © {date} - Samantha Dangu
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
