import React, { useState } from 'react';

const NavbarComponent: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleNavbar = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="fixed z-50 w-full top-0 flex flex-wrap items-center justify-between shadow-lg py-2" style={{ backgroundColor: 'var(--color-background)' }}>
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white" href="/">Accueil</a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={toggleNavbar}
          >
            <i className="text-white fas fa-bars"></i>
          </button>
        </div>

        <div className={`lg:flex flex-grow items-center lg:bg-transparent ${showMenu ? 'block' : 'hidden'}`}>
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="#propos">
                A propos de moi
              </a>
            </li>
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="#projets">
                Projets
              </a>
            </li>
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="#parcours">
                Parcours
              </a>
            </li>
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="#contact">
                Contactez-moi
              </a>
            </li>
          </ul>
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://goo.gl/maps/iD7JF7ut7BEMF9TA7">
                <i className="lg:text-gray-300 text-white fas fa-map-marker text-lg leading-lg "></i>
              </a>
            </li>
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://www.linkedin.com/in/samantha-dangu/">
                <i className="lg:text-gray-300 text-white fab fa-linkedin text-lg leading-lg "></i>
              </a>
            </li>
            <li className="flex items-center">
              <a className="lg:text-white lg:hover:text-gray-300 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://github.com/SamanthaDangu">
                <i className="lg:text-gray-300 text-white fab fa-github text-lg leading-lg "></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
