import React from "react";
import "./parcours.css";
import ParcoursProfessionnel from "./parcoursProfessionnel";
import ParcoursScolaire from "./parcoursScolaire";

function Parcours() {

  return (
    <section id="parcours" className="pb-20 relative block bg-gray-900">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "81px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-900 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <ParcoursProfessionnel />
      <ParcoursScolaire />
    </section>
  );
}

export default Parcours;
