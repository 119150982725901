import React, { useState, useEffect } from "react";
import Projet from "../../models/projet";
import ProjetService from "../../services/projet-service";
import "./projet.css";
import ProjetCard from "./projet-card";

function Projets() {
  const [projetsList, setProjetsList] = useState<Projet[]>([]);

  useEffect(() => {
    ProjetService.getProjets().then((projets) => setProjetsList(projets));
  }, []);

  return (
    <section id="projets" className="bg-white relative py-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "81px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto p-4">
        <div className="container mx-auto px-4 mb-6 flex justify-center">
          <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
            <i className="fas fa-briefcase text-xl"></i>
          </div>
          <h3 className="text-3xl p-3 mb-2 font-semibold leading-normal">
            Mes projets !
          </h3>
        </div>
        <div className="flex flex-wrap">
          {projetsList.map((projet, index) => (
            <ProjetCard key={index} projet={projet} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projets;
