import ParcoursScolaire from "../models/parcours-sco";

export default class ParcoursScolaireService {
  static getParcoursScolaires(): Promise<ParcoursScolaire[]> {
    return fetch("https://back.samantha-dangu.fr/api/parcours-scolaire")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.parcoursScolaire)) {
          return data.parcoursScolaire;
        } else {
          throw new Error("Invalid response data format");
        }
      })
      .catch((error) => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}
