import React from "react";
import Intro from "../components/intro";
import About from "../components/about/about";
import Projets from "../components/projets/projet";
import Parcours from "../components/parcours/parcours";
import Contact from "../components/contact";

export default function Landing() {
  return (
    <>
      <main>
        <Intro />
        <About />
        <Projets />
        <Parcours />
        <Contact />
      </main>
    </>
  );
}
