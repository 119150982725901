import React, { FunctionComponent } from "react";
import PageNotFound from "./pages/page-not-found";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavbarComponent from "./components/navbar";
import Landing from "./pages/landing";

const App: FunctionComponent = () => {
  return (
    <Router>
      <div>
        <header>
          <NavbarComponent />
        </header>

        <Switch>
          <Route exact path="/" component={Landing} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
