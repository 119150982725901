import React from 'react';
import './intro.css'

const Intro: React.FC = () => {
  return (
    <div className="relative pt-16 pb-32 flex content-center items-center justify-center" style={{ minHeight: '75vh' }}>
      <div className="absolute top-0 w-full h-full bg-center bg-cover" style={{ backgroundImage: 'url("assets/images/code.jpg")' }}>
        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
      </div>
      <div className="container relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <img className="mx-auto picture" src="assets/images/logo.svg" alt="Samantha Dangu, développeuse web" />
            <h1 className="text-white font-semibold text-5xl">Samantha Dangu</h1>
            <p className="mt-4 text-lg text-gray-300">
              Développeuse web FullStack full remote, résidant en Île-de-France ! Bienvenue sur mon portefolio 😄
            </p>
            <div className="text-center mt-6">
              <a
                href="#projets"
                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                style={{ transition: 'all 0.15s ease 0s' }}
                type="button"
              >
                Voir mes projets
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
