import React, { useEffect, useState } from "react";
import Langage from "../../models/langage";
import LangageService from "../../services/langage-service";
import Framework from "../../models/framework";
import FrameworkService from "../../services/framework-service";
import Bibliotheque from "../../models/bibliotheque";
import BibliothequeService from "../../services/bibliotheque-service";
import Outil from "../../models/outil";
import OutilService from "../../services/outil-service";

const Technologies: React.FC = () => {
  const [langagesList, setLangagesList] = useState<Langage[]>([]);
  const [frameworksList, setFrameworksList] = useState<Framework[]>([]);
  const [bibliothequesList, setBibliothequesList] = useState<Bibliotheque[]>(
    []
  );
  const [outilsList, setOutilsList] = useState<Outil[]>([]);

  useEffect(() => {
    LangageService.getLangages().then((langages) => setLangagesList(langages));

    FrameworkService.getFrameworks().then((frameworks) =>
      setFrameworksList(frameworks)
    );
    BibliothequeService.getBibliotheques().then((bibliotheques) =>
      setBibliothequesList(bibliotheques)
    );
    OutilService.getOutils().then((outils) => setOutilsList(outils));
  }, []);

  return (
    <div className="flex flex-wrap">
      <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="p-4 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-2 shadow-lg rounded-full bg-red-400">
              <i className="fas fa-code"></i>
            </div>
            <h6 className="text-xl font-semibold">Langages</h6>
            <div className="flex flex-wrap justify-center lg:justify-between">
              {langagesList.map((item, index) => (
                <img
                  key={index}
                  src={item.image}
                  alt=""
                  className="w-12 h-12 mx-10 my-2 fill-current md:mx-12 lg:m-0 dark:text-gray-400 m-1"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="p-4 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-2 shadow-lg rounded-full bg-blue-400">
              <i className="fas fa-puzzle-piece"></i>
            </div>
            <h6 className="text-xl font-semibold">Frameworks / Librairies</h6>
            <div className="flex flex-wrap justify-center lg:justify-between">
              {frameworksList.map((item, index) => (
                <img
                  key={index}
                  src={item.image}
                  alt=""
                  className="w-12 h-12 mx-10 my-2 fill-current md:mx-12 lg:m-0 dark:text-gray-400 m-1"
                />
              ))}
            </div>
            <div className="flex flex-wrap justify-center lg:justify-between">
              {bibliothequesList.map((item, index) => (
                <img
                  key={index}
                  src={item.image}
                  alt=""
                  className="w-12 h-12 mx-10 my-2 fill-current md:mx-12 lg:m-0 dark:text-gray-400 m-1"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 w-full md:w-4/12 px-4 text-center">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
          <div className="p-4 flex-auto">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-2 shadow-lg rounded-full bg-green-400">
              <i className="fas fa-wrench"></i>
            </div>
            <h6 className="text-xl font-semibold">Outils</h6>
            <div className="flex flex-wrap justify-center lg:justify-between">
              {outilsList.map((item, index) => (
                <img
                  key={index}
                  src={item.image}
                  alt=""
                  className="w-12 h-12 mx-10 my-2 fill-current md:mx-12 lg:m-0 dark:text-gray-400 m-1"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
