import Langage from "../models/langage";

export default class LangageService {
  static getLangages(): Promise<Langage[]> {
    return fetch("https://back.samantha-dangu.fr/api/langages")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.langages)) {
          return data.langages;
        } else {
          throw new Error("Invalid response data format");
        }
      })
      .catch((error) => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}
