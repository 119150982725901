import React, { FunctionComponent } from "react";
import Projet from "../../models/projet";

type ProjetCardProps = {
  projet: Projet;
};

const ProjetCard: FunctionComponent<ProjetCardProps> = ({ projet }) => {
  return (
    <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4">
      <a href={projet.lien_sous_domaine} target="_blank">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8">
          <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <img className="w-full" src={projet.image} alt={projet.titre} />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{projet.titre}</div>
              <div className="flex items-center mb-2">
                <i className="fab fa-github mr-2"></i>
                <a
                  href={projet.lien_git}
                  target="_blank"
                  className="text-gray-700 text-base"
                >
                  Voir sur GitHub
                </a>
              </div>
            </div>
            <div className="px-6 pt-0 pb-2">
              {projet.langages.map((langage, langageIndex) => (
                <span
                  key={langageIndex}
                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                  #{langage.nom}
                </span>
              ))}
              {projet.frameworks.map((framework, frameworkIndex) => (
                <span
                  key={frameworkIndex}
                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                  #{framework.nom}
                </span>
              ))}
              {projet.bibliotheques.map((bibliotheque, bibliothequeIndex) => (
                <span
                  key={bibliothequeIndex}
                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                  #{bibliotheque.nom}
                </span>
              ))}
              {projet.outils.map((outil, outilIndex) => (
                <span
                  key={outilIndex}
                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                  #{outil.nom}
                </span>
              ))}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProjetCard;
