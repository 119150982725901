import React, { useState } from "react";
import emailjs from "emailjs-com";
import * as yup from "yup";
import FooterComponent from "./footer";

const Contact = () => {
  const [nomPrenom, setNomPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [objet, setObjet] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});

  const schema = yup.object({
    nomPrenom: yup
      .string()
      .max(50)
      .required("Merci de rentrer votre nom et prénom"),
    email: yup
      .string()
      .email("Merci de rentrer une adresse mail valide")
      .max(255)
      .required("Merci de rentrer une adresse mail"),
    objet: yup.string().required("Merci de rentrer un objet"),
    message: yup.string().required("Merci de rentrer un message"),
  });

  const envoyerMessage = async () => {
    try {
      // Valider les données
      await schema.validate({
        nomPrenom,
        email,
        objet,
        message,
      });

      // Crée un objet pour les informations du message
      const messageInfo = {
        nomPrenom,
        email,
        objet,
        message,
      };

      // Envoie l'email
      await emailjs.send(
        "service_bu5v79n",
        "template_ow51fnc",
        messageInfo,
        "yI1mHsQFzc07w466a"
      );

      // Réinitialise les champs après l'envoi du message
      setNomPrenom("");
      setEmail("");
      setObjet("");
      setMessage("");
      // Réinitialise les erreurs
      setErrors({});
    } catch (error) {
      // En cas d'erreur, affiche un message à l'utilisateur
      console.log(error);
      alert(
        "Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer plus tard."
      );
    }
  };

  return (
    <div
      id="contact"
      className="relative flex flex-col justify-between min-h-screen"
    >
      <div
        className="absolute top-0 w-full h-full bg-center bg-cover"
        style={{ backgroundImage: 'url("assets/images/code.jpg")' }}
      >
        <span
          id="blackOverlay"
          className="w-full h-full absolute opacity-75 bg-black"
        ></span>
      </div>
      <div className="container relative mx-auto flex-grow pt-24">
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
              <div className="flex-auto p-5 lg:p-10">
                <h4 className="text-2xl font-semibold">
                  Besoin de me contacter ?
                </h4>
                <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                  Je suis ouverte à toute opportunité.
                </p>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Nom & Prénom
                    </label>
                    <input
                      type="text"
                      value={nomPrenom}
                      onChange={(e) => setNomPrenom(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="John Doe"
                      required
                      style={{ transition: "all 0.15s ease 0s" }}
                    />
                    {errors.nomPrenom && (
                      <p className="text-red-500 text-xs">{errors.nomPrenom}</p>
                    )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Email"
                      required
                      style={{ transition: "all 0.15s ease 0s" }}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="objet"
                    >
                      Objet
                    </label>
                    <input
                      type="text"
                      value={objet}
                      onChange={(e) => setObjet(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Objet du message"
                      required
                      style={{ transition: "all 0.15s ease 0s" }}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Écrivez votre message..."
                      required
                    />
                    {errors.message && (
                      <p className="text-red-500 text-xs">{errors.message}</p>
                    )}
                  </div>
                  <div className="text-center mt-6">
                    <button
                      onClick={envoyerMessage}
                      type="submit"
                      className="bg-gray-900 text-white active-bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default Contact;
