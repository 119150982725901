import React, { useState, useEffect } from "react";
import ParcoursSco from "../../models/parcours-sco";
import ParcoursScolaireService from "../../services/parcoursScolaire-service";

function ParcoursScolaire() {
  const [parcoursScoList, setParcoursScoList] = useState<ParcoursSco[]>(
    []
  );

  useEffect(() => {
    ParcoursScolaireService.getParcoursScolaires().then((parcours) => {
      setParcoursScoList(parcours);
    });
  }, []);

  return (
    <div className="container mx-auto px-4 lg:pt-24 lg:pb-32">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold text-white">
            Parcours scolaire
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap mt-12 justify-center">
        {parcoursScoList.map((item, index) => (
          <div className="w-full lg:w-3/12 px-4 text-center" key={index}>
            <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className={item.icone}></i>
            </div>
            <h6 className="text-xl mt-5 font-semibold text-white">
              {" "}
              {item.diplome}{" "}
            </h6>
            <p className="mt-2 mb-4 text-gray-500"> {item.école} </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ParcoursScolaire;
