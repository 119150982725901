import React from "react";

function Presentation() {
  return (
    <div className="flex flex-wrap items-center mt-20">
      <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
        <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
          <i className="fas fa-quote-left text-xl"></i>
        </div>
        <h3 className="text-3xl mb-2 font-semibold leading-normal">
          Laissez moi me présenter !
        </h3>
        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
          🌟 Après des années dans l'administratif, j'ai suivi ma passion et
          suis devenue développeuse web grâce à une formation intensive de 6
          mois chez <strong>O'Clock</strong>. 💻 <br /> <br />
          🎓 En mai 2022, j'ai obtenu mon titre de développeuse web, mais ma
          soif d'apprentissage n'a pas disparu ! J'ai ensuite suivi une
          formation de 2 semaines sur le design sprint et me suis perfectionnée
          en assurance qualité web grâce à Opquast, obtenant la certification{" "}
          <em>"Maîtrise de la qualité en projet Web"</em> avec un score de plus
          de 750 points. 🎉
          <br /> <br />
          💼 Ma recherche d'entreprise pour mon alternance m'a conduite à une
          opportunité passionnante : travailler à la fois pour une entreprise et
          une startup d'État, l'<strong>ANCT</strong> (Agence Nationale de la
          Cohésion des Territoires). 🚀
          <br /> <br />
          🤝 Bien que l'entreprise n'ait pas pu me conserver à temps plein, j'ai
          continué à travailler pour l'<strong>ANCT</strong> en freelance
          pendant 6 mois en tant que consultante. 🧑‍💼
          <br /> <br />
          🌄 Maintenant que ma mission est terminée, je me demande quelle sera
          ma prochaine aventure. Une chose est sûre : je suis prête à continuer
          dans ce domaine qui m'inspire tant ! 🚀
          <br /> <br />
        </p>
      </div>
      <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
        <div
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg"
          style={{ backgroundColor: "var(--color-background)" }}
        >
          <img
            alt="photo Samantha Dangu, développeuse web"
            src="assets/images/samantha.jpg"
            className="w-full align-middle rounded-t-lg"
          />
          <blockquote className="relative p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              className="absolute left-0 w-full block"
              style={{ height: "95px", top: "-94px" }}
            >
              <polygon points="-30,95 583,95 583,65"></polygon>
            </svg>
            <h4 className="text-xl font-bold text-white">
              Qu'est-ce que je recherche ?
            </h4>
            <p className="text-md font-light mt-2 text-white">
              Actuellement, je suis à la recherche d'opportunités de mission
              axées sur React, Vue ou Symfony. Je suis ouverte tant à
              l'alternance CDA qu'à un poste en CDI.
            </p>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
