import Outil from "../models/outil";

export default class OutilService {
  static getOutils(): Promise<Outil[]> {
    return fetch("https://back.samantha-dangu.fr/api/outils")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.outils)) {
          return data.outils;
        } else {
          throw new Error("Invalid response data format");
        }
      })
      .catch((error) => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}
