import React, { useState, useEffect } from "react";
import ParcoursPro from "../../models/parcours-pro";
import ParcoursProfessionnelService from "../../services/parcoursPro-service";

function ParcoursProfessionnel() {
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const [parcoursProList, setParcoursProList] = useState<ParcoursPro[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(2);

  useEffect(() => {
    const perPage = 5;
    ParcoursProfessionnelService.getParcoursPros(currentPage, perPage).then(
      (parcours) => {
        setParcoursProList(parcours);
      }
    );
  }, [currentPage]);

  function toggleAccordion(index: number) {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  }

  function loadPage(page: number) {
    if (page >= 1 && page <= lastPage) {
      setCurrentPage(page);
    }
  }

  return (
    <div className="container mx-auto px-4 lg:pt-24 ">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold text-white">
            Expérience professionnelle
          </h2>
        </div>
      </div>
      <div className="mt-12">
        <div className="accordion" id="accordionExample">
          {parcoursProList.map((item, index) => (
            <div
              className="accordion-item bg-gray-900 text-white border border-gray-700 rounded mb-2"
              key={index}
            >
              <h2 className="accordion-header">
                <button
                  className={`accordion-button w-full text-left py-3 px-4 bg-gray-700 text-white border border-gray-700 ${
                    expandedIndex === index ? "collapsed" : ""
                  }`}
                  type="button"
                  onClick={() => toggleAccordion(index)}
                  aria-controls={`collapseOne${index}`}
                >
                  <i className={item.icone + " mr-2"}></i> {item.titre} -{" "}
                  {item.entreprise} {item.lieu}
                </button>
              </h2>
              <div
                id={`collapseOne${index}`}
                className={`accordion-collapse collapse ${
                  expandedIndex === index ? "show" : ""
                }`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body p-4">{item.description}</div>
              </div>
            </div>
          ))}
        </div>
        <nav className="flex flex-wrap text-center justify-center mt-2">
          <ul className="pagination">
            {currentPage > 1 && (
              <li className="page-item">
                <button
                  className="page-link bg-gray-700 text-white border border-gray-700"
                  onClick={() => loadPage(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
            )}
            {Array.from({ length: lastPage }, (v, k) => k + 1).map((page) => (
              <li
                key={page}
                className={`page-item ${currentPage === page ? "active" : ""}`}
              >
                <button
                  className="page-link bg-gray-700 text-white border border-gray-700"
                  onClick={() => loadPage(page)}
                >
                  {page}
                </button>
              </li>
            ))}
            {currentPage < lastPage && (
              <li className="page-item">
                <button
                  className="page-link bg-gray-700 text-white border border-gray-700"
                  onClick={() => loadPage(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default ParcoursProfessionnel;
